import * as React from 'react';

import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader } from 'ebs-design';
import { Column, OpportunityRefusal } from 'types';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useReportsAPI } from 'api';
import { useQueryParams } from 'hooks/useQueryParams';
import { ReportOpportunityFilter } from 'features/reports/Filters';

type ReportTableType = {
  id: string;
  full_name: string;
  email: string;
  name: string;
  count: number;
};

export const ReportOpportunitiesRefusal: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getReportOpportunitiesRefusal } = useReportsAPI();

  const { data, isLoading } = useQuery<OpportunityRefusal[]>(
    [
      'reports-opportunities-refusal',
      {
        ...params,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
      },
    ],
    getReportOpportunitiesRefusal,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const tableFormattedData = React.useMemo(() => {
    if (!data) return [];
    return data.flatMap((item) =>
      item.refusal_reasons.map((reason, index) => ({
        id: item.id,
        full_name: index === 0 ? `${item.first_name} ${item.last_name}` : '',
        email: index === 0 ? item.email : '',
        name: reason.name,
        count: reason.count,
      })),
    );
  }, [data]);

  const columns: Column<ReportTableType>[] = React.useMemo(
    () => [
      {
        title: t('lead.fullName'),
        dataIndex: 'full_name',
      },
      {
        title: t('contacts.email'),
        dataIndex: 'email',
      },
      {
        title: t('opportunities.refusalReasons'),
        align: 'center',
        children: [
          {
            title: t('nomenclature.name'),
            dataIndex: 'name',
          },
          {
            title: t('opportunities.count'),
            dataIndex: 'count',
          },
        ],
      },
    ],
    [t],
  );

  return (
    <Card className="overflow-visible">
      <Loader loading={isLoading} size="regular">
        <Card.Header>
          <ReportOpportunityFilter count={data?.length} title="opportunitiesRefusal" />
        </Card.Header>

        <Table
          data={tableFormattedData}
          columns={columns}
          className="no-border contacts-table"
          emptyText={t('main.noData')}
        />
      </Loader>
    </Card>
  );
};
