import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader, Space } from 'ebs-design';
import { useQueryParams } from 'hooks/useQueryParams';
import { dateFormatAPI, format, dateFormat } from 'libs';
import { Column, OnlineReport } from 'types';
import { useReportsAPI } from 'api';
import { ReportOnlineFilter } from 'features/reports/Filters';

export const ReportOnline: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getOnlineReports } = useReportsAPI();

  const { data: reportsOnline, isLoading } = useQuery<OnlineReport[]>(
    [
      'reports-online',
      {
        ...params,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
        date: undefined,
      },
    ],
    getOnlineReports,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const columns: Column<OnlineReport>[] = React.useMemo(
    () => [
      {
        title: t('reports.visits'),
        dataIndex: 'visits',
      },
      {
        title: t('reports.leads'),
        dataIndex: 'name',
      },
      {
        title: t('reports.conversion_leads'),
        dataIndex: 'conversion_leads',
      },
      {
        title: t('reports.nr_opportunities'),
        dataIndex: 'opportunities',
      },
      {
        title: t('reports.conversion_opportunities'),
        dataIndex: 'conversion_opportunities',
      },
      {
        title: t('reports.signed_contracts'),
        render: ({ signed_contracts }) => `№ ${signed_contracts}`,
      },
      {
        title: t('reports.conversion_contracts'),
        dataIndex: 'conversion_contracts',
      },
      {
        title: t('reports.signed_contracts_lei'),
        render: ({ sum_signed_contracts }) => `${sum_signed_contracts} lei`,
      },
      {
        title: t('reports.media_val_contracts'),
        dataIndex: 'avg_signed_contracts',
      },
      {
        title: t('reports.marketing_cost'),
        dataIndex: 'marketing_cost',
      },
      {
        title: t('reports.roi'),
        render: ({ roi }) => `${roi} %`,
      },
      {
        title: t('reports.cost_per_visit'),
        dataIndex: 'cost_per_visit',
      },
      {
        title: t('reports.budget'),
        dataIndex: 'budget',
      },
      {
        title: t('reports.variation'),
        render: ({ variation }) => `${variation} %`,
      },
    ],
    [t, reportsOnline],
  );

  return (
    <Card className="overflow-visible">
      <Loader loading={isLoading} size="regular">
        <Card.Header>
          <Space justify="space-between">
            <Space>
              <h3 className="title">{t(`reports.reportOnline`)}</h3>
            </Space>
            <Space>
              <ReportOnlineFilter count={reportsOnline?.length} />
            </Space>
          </Space>
        </Card.Header>
        <Table
          data={reportsOnline}
          columns={columns}
          className="no-border reports-table"
          emptyText={t('main.noData')}
        />
      </Loader>
    </Card>
  );
};
