import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useReportsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getReportCalls: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/calls-reports', query), getRequest);
    },
    getCallsWeightDiagram: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/calls-weight-diagram', query), getRequest);
    },
    getContractsChart: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/contracts-chart', query), getRequest);
    },
    getContractsWeightDiagram: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/contracts-weight-diagram', query), getRequest);
    },
    getCreditApplicationsChart: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/credit-applications-chart', query), getRequest);
    },
    getLeadsWeightDiagram: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/leads-weight-diagram', query), getRequest);
    },
    getOfflineReports: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/offline-reports', query), getRequest);
    },
    getOnlineReports: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/online-reports', query), getRequest);
    },
    getPhoneCallsChart: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/phone-calls-chart', query), getRequest);
    },
    getSalesFunnel: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/sales-funnel', query), getRequest);
    },
    getSalesWeightDiagram: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/sales-weight-diagram', query), getRequest);
    },
    getVisitChart: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/visit-chart', query), getRequest);
    },
    getReportVisits: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/visits', query), getRequest);
    },
    getReportCallsBranches: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/branches-calls-reports', query), getRequest);
    },
    getReportOpportunities: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/opportunities', query), getRequest);
    },
    getReportOpportunitiesBranches: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/opportunities-branches', query), getRequest);
    },
    getReportOpportunitiesBranchesRefusal: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/opportunities-branches-refusal', query), getRequest);
    },
    getReportOpportunitiesRefusal: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl('/reports/opportunities-refusal', query), getRequest);
    },
  };
};
