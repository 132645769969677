import * as React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  Actions,
  Card,
  Checkbox,
  Radio,
  Table,
  useNotify,
  Loader,
  Modal,
  Space,
  Button,
} from 'ebs-design';
import { useLeadsAPI } from 'api';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { AddressCell } from 'components/molecules';
import { Pagination } from 'components/organisms';
import { ContactFilter } from 'features/contacts/Filters/ContactFilter';
import { AssignTo, MoveTo } from 'features/contacts/Actions';
import { Lead, Results, Column, FilterType, Properties } from 'types';
import { dateFormat, dateFormatAPI, dateTimeFormat, format } from 'libs';
import { defaultFilters } from 'utils';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';

export const Contacts: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { updateQuery } = useQueryUpdate();

  const { getLeads, patchLead, changeStatus, deleteLeads } = useLeadsAPI();

  const [checkedLeads, setCheckedLeads] = React.useState<number[]>([]);
  const [assignTo, setAssignTo] = React.useState<number[]>();
  const [moveTo, setMoveTo] = React.useState<number[]>();
  const [deleteItems, setDeleteItems] = React.useState<number[]>([]);

  const { data: leads, isLoading } = useQuery<Results<Lead>>(
    [
      'contacts',
      {
        ...defaultFilters,
        ...params,
        period_start: params.period_start && format(params.period_start, dateFormatAPI, dateFormat),
        period_end: params.period_end && format(params.period_end, dateFormatAPI, dateFormat),
        period: undefined,
      },
    ],
    getLeads,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const changeStatusMutation = useMutation(
    (body: { id: number; status: string }) => patchLead(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contacts']);
      },
    },
  );

  const changeStatusesMutation = useMutation((body: Properties) => changeStatus(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['contacts']);
    },
  });

  const deleteLeadsMutation = useMutation(deleteLeads, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: (e: Properties) => {
      queryClient.invalidateQueries(['contacts']);
      setDeleteItems([]);
      notify.success({ title: e.message });
    },
  });

  const onPatchLeadStatus = (status: string, id: number): void =>
    changeStatusMutation.mutate({ id, status });

  const onCheckLead = (type: boolean, id: number): void => {
    if (type) {
      setCheckedLeads((prevCheckedLeads) => [...prevCheckedLeads, id]);
    } else {
      setCheckedLeads((prevCheckedLeads) => prevCheckedLeads.filter((item) => item !== id));
    }
  };

  const onCheckAll = (): void => {
    if (leads?.results.length !== checkedLeads.length) {
      setCheckedLeads(leads?.results.map((item) => item.id) || []);
    } else {
      setCheckedLeads([]);
    }
  };

  const handleCloseAssignTo = (): void => {
    setAssignTo(undefined);
  };
  const handleCloseMoveTo = (): void => {
    setMoveTo(undefined);
  };

  const handleDeleteItems = (): void => {
    deleteLeadsMutation.mutate({ leads: deleteItems } as any);
  };

  const handleCancelDelete = (): void => {
    setDeleteItems([]);
  };

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const onChangeStatus = (value?: string | number): void => {
    changeStatusesMutation.mutate({ status: value, leads: checkedLeads });
  };

  const columns: Column<Lead>[] = React.useMemo(
    () => [
      {
        title: (
          <Checkbox onChange={onCheckAll} checked={leads?.results.length === checkedLeads.length} />
        ),
        render: ({ id }): React.ReactNode => (
          <Checkbox
            onChange={(type) => onCheckLead(type, id)}
            checked={checkedLeads.find((item) => item === id) ? true : false}
          />
        ),
      },
      {
        title: t('contacts.firstName'),
        onFilter: (type) => handleFilter(type, 'primary_contact__first_name'),
        render: ({ id, contact }) => <Link to={`/lead/${id}`}>{contact?.first_name}</Link>,
      },
      {
        title: t('contacts.lastName'),
        onFilter: (type) => handleFilter(type, 'primary_contact__last_name'),
        render: ({ id, contact }) => <Link to={`/lead/${id}`}>{contact?.last_name}</Link>,
      },
      {
        title: t('contacts.companyName'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ id, name }) => <Link to={`/lead/${id}`}>{name}</Link>,
      },
      {
        title: t('contacts.phoneNumber'),
        render: ({ id, contact }) => (
          <Link className="medium" to={`/lead/${id}`}>
            {contact.phone_number}
          </Link>
        ),
      },
      {
        title: t('contacts.location'),
        render: ({
          address_country,
          address_region,
          address_city,
          address_street,
        }): React.ReactNode => (
          <div className="medium">
            <AddressCell
              address_country={address_country}
              address_region={address_region}
              address_city={address_city}
              address_street={address_street}
            />
          </div>
        ),
      },
      {
        title: t('contacts.jobTitle'),
        render: ({ contact }): React.ReactNode => (
          <div className="medium">{contact?.job_title}</div>
        ),
      },
      {
        title: t('contacts.creditExpert'),
        render: ({ owner }): React.ReactNode => (
          <div className="medium">{owner?.name || '---'}</div>
        ),
      },
      {
        title: t('contacts.status'),
        render: ({ status }): React.ReactNode => (
          <div className="medium">{t(`statuses.${status}`)}</div>
        ),
      },
      {
        title: t('created_at'),
        onFilter: (type) => handleFilter(type, 'created_at'),
        dataIndex: 'created_at',
        render: (created_at) => (
          <div className="medium">{created_at ? format(created_at, dateTimeFormat) : '---'}</div>
        ),
      },
      ...(useRolePermission([
        UserRoleCRM.admin,
        UserRoleCRM.expert,
        UserRoleCRM.manager,
        UserRoleCRM.marketing,
      ])
        ? [
            {
              align: 'right',
              title: !!checkedLeads.length && (
                <Actions showTitle={false}>
                  <div className="table-actions">
                    <div className="actions-title">{t('contacts.action')}</div>
                    <Actions.Item onClick={() => setAssignTo(checkedLeads)}>
                      {t('contacts.reAssignResponsible')}
                    </Actions.Item>
                    <Actions.Item onClick={() => setMoveTo(checkedLeads)}>
                      {t('contacts.moveTo')}
                    </Actions.Item>
                    <Actions.Item onClick={() => setDeleteItems(checkedLeads)}>
                      {t('contacts.deleteRecord')}
                    </Actions.Item>
                    <div className="actions-title">{t('contacts.status')}</div>
                    <Actions.Item>
                      <Radio
                        name="status"
                        options={[{ text: t('filter.prospecting'), value: 'prospecting' }]}
                        className="actions-radio"
                        onChange={onChangeStatus}
                      />
                    </Actions.Item>
                    <Actions.Item>
                      <Radio
                        name="status"
                        options={[{ text: t('filter.notInterested'), value: 'not_interested' }]}
                        className="actions-radio"
                        onChange={onChangeStatus}
                      />
                    </Actions.Item>
                  </div>
                </Actions>
              ),
              render: ({ status, id }) => (
                <Actions showTitle={false}>
                  <div className="table-actions">
                    <div className="actions-title">{t('contacts.action')}</div>
                    <Link to={`/lead/${id}`}>
                      <Actions.Item onClick={() => setAssignTo(checkedLeads)}>
                        {t('contacts.viewLead')}
                      </Actions.Item>
                    </Link>
                    <Actions.Item onClick={() => setAssignTo([id])}>
                      {t('contacts.reAssignResponsible')}
                    </Actions.Item>
                    <Actions.Item onClick={() => setMoveTo([id])}>
                      {t('contacts.moveTo')}
                    </Actions.Item>
                    <Actions.Item onClick={() => setDeleteItems([id])}>
                      {t('contacts.deleteRecord')}
                    </Actions.Item>
                    <div className="actions-title">{t('contacts.status')}</div>
                    <Actions.Item>
                      <Radio
                        name="status"
                        options={[{ text: t('filter.prospecting'), value: 'prospecting' }]}
                        className="actions-radio"
                        value={status}
                        onChange={() => onPatchLeadStatus('prospecting', id)}
                      />
                    </Actions.Item>
                    <Actions.Item>
                      <Radio
                        name="status"
                        options={[{ text: t('filter.notInterested'), value: 'not_interested' }]}
                        className="actions-radio"
                        value={status}
                        onChange={() => onPatchLeadStatus('not_interested', id)}
                      />
                    </Actions.Item>
                  </div>
                </Actions>
              ),
            } as Column<Lead>,
          ]
        : []),
    ],
    [t, leads, checkedLeads, params],
  );

  return (
    <>
      {assignTo && <AssignTo leads={assignTo} onClose={handleCloseAssignTo} />}

      {moveTo && <MoveTo leads={moveTo} onClose={handleCloseMoveTo} />}

      {!!deleteItems?.length && (
        <Modal
          closeOnClickOutside={false}
          open
          header={<h3>{t('confirm.areYouSure')}</h3>}
          onClose={handleCancelDelete}
        >
          <Modal.Content>{t('confirm.areYouSureDescription')}</Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={handleCancelDelete}>{t('buttons.cancel')}</Button>
              <Button type="primary" onClick={handleDeleteItems}>
                {t('buttons.ok')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}

      <ContactFilter count={leads?.count} />

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            data={leads?.results}
            columns={columns}
            className="contacts-table no-border"
            rowClassName={(data) => (!data?.owner ? 'contacts-table-row' : '')}
            emptyText={t('main.noData')}
          />
        </Loader>
        <Card.Footer>
          <Pagination size={leads?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
