import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, ButtonGroup, Icon, Space } from 'ebs-design';
import { isCurrentURl } from 'utils';
import { reportsRoutes } from './reports';

export const ReportsLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const renderButton = (path: string, label: string, icon?: React.FC): React.ReactElement => (
    <Link to={path} key={label}>
      <Button
        prefix={icon ? <Icon component={icon} /> : undefined}
        className={cn('reports-layout__navigation__link', {
          active: isCurrentURl(path),
        })}
      >
        {t(`reports.${label}`)}
      </Button>
    </Link>
  );

  const renderGroup = (group: typeof reportsRoutes[number]): React.ReactElement => (
    <ButtonGroup className="reports-layout__navigation__link-group" key={group.label}>
      {group.children?.map((child, index) =>
        renderButton(child.path, child.label, !index ? group.icon : undefined),
      )}
    </ButtonGroup>
  );

  const renderReports = (): React.ReactElement[] =>
    reportsRoutes.map((report) =>
      report?.children ? renderGroup(report) : renderButton(report.path, report.label, report.icon),
    );

  return (
    <div className="reports-layout">
      <Space justify="start" size={20} wrap className="reports-layout__navigation mb-20">
        <h3 className="title">{t(`reports.reports`)}</h3>
        {renderReports()}
      </Space>

      {children}
    </div>
  );
};
