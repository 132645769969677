import { User } from './user';
import { Contact } from './contacts';
import { Nomenclatures } from './nomenclatures';
import { Currency } from './currency';
import { Document } from './documents';

export interface ShortOpportunity {
  id: number;
  currency: Currency;
  value: number;
}

export interface Opportunity extends ShortOpportunity {
  notes: string;
  close_date: string;
  created_at: string;
  status: OpportunityStatus;
  term: number;
  contact: Contact;
  product: Nomenclatures;
  lead: number;
  refusal_reason: RefusalReasonType;
  owner: User;
  documents: Document[];
}

export enum OpportunityStatus {
  PROSPECTING = 'prospecting',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  SIGNED = 'signed',
  REQUEST_ON_APPROVE = 'request_on_approve',
  REQUEST_APPROVED = 'request_approved',
  REQUEST_DECLINED = 'request_declined',
  REQUEST_ANNULLED = 'request_annulled',
}

export const opportunityLabel = {
  prespecting: 'warning',
  sent: 'success',
  accepted: 'success',
  declined: 'danger',
  signed: 'success',
  request_on_approve: 'info',
  request_approved: 'success',
  request_declined: 'danger',
  request_annulled: 'danger',
};

export interface RefusalReasonType {
  id: number;
  modified_at: string;
  created_at: string;
  name: string;
  is_default: boolean;
}

export interface ReportsOpportunitiesTypes {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  prospecting: number;
  sent: number;
  accepted: number;
  declined: number;
  signed: number;
  name: string;
  request_on_approve: number;
  request_approved: number;
  request_declined: number;
  request_annulled: number;
}

export interface TotalOpportunitiesType {
  total_accepted: number;
  total_declined: number;
  total_prospecting: number;
  total_request_annulled: number;
  total_request_approved: number;
  total_request_declined: number;
  total_request_on_approve: number;
  total_sent: number;
  total_signed: number;
}

export interface ReportsOpportunitiesBranchesType {
  branches: ReportsOpportunitiesTypes[];
  totals: TotalOpportunitiesType;
}

export interface ReportOpportunitiesBranchesRefusalType {
  id: string;
  refusal_reasons: OpportunityBranchesRefusalReason[];
  name: string;
}

export interface OpportunityBranchesRefusalReason {
  id: number;
  modified_at: string;
  created_at: string;
  name: string;
  count: number;
  is_default: boolean;
}

export interface OpportunityRefusal {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  refusal_reasons: OpportunityBranchesRefusalReason[];
}
