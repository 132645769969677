import * as React from 'react';

import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader } from 'ebs-design';
import { Column, ReportsOpportunitiesBranchesType, ReportsOpportunitiesTypes } from 'types';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useReportsAPI } from 'api';
import { useQueryParams } from 'hooks/useQueryParams';
import { ReportOpportunityFilter } from 'features/reports/Filters';

export const ReportOpportunitiesBranches: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getReportOpportunitiesBranches } = useReportsAPI();

  const { data, isLoading } = useQuery<ReportsOpportunitiesBranchesType>(
    [
      'reports-opportunities-branches',
      {
        ...params,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
      },
    ],
    getReportOpportunitiesBranches,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
      select: (data) =>
        ({
          ...data,
          branches: [
            ...data.branches,
            {
              id: 'totals',
              name: t('reports.total'),
              prospecting: data?.totals?.total_prospecting,
              sent: data?.totals?.total_sent,
              accepted: data?.totals?.total_accepted,
              declined: data?.totals?.total_declined,
              signed: data?.totals?.total_signed,
              request_on_approve: data?.totals?.total_request_on_approve,
              request_approved: data?.totals?.total_request_approved,
              request_declined: data?.totals?.total_request_declined,
              request_annulled: data?.totals?.total_request_annulled,
            },
          ],
        } as ReportsOpportunitiesBranchesType),
    },
  );

  const columns: Column<ReportsOpportunitiesTypes>[] = React.useMemo(
    () => [
      {
        title: t('documents.name'),
        dataIndex: 'name',
      },
      {
        title: t('opportunities.prospecting'),
        dataIndex: 'prospecting',
      },
      {
        title: t('opportunities.sent'),
        dataIndex: 'sent',
      },
      {
        title: t('opportunities.accepted'),
        dataIndex: 'accepted',
      },
      {
        title: t('opportunities.declined'),
        dataIndex: 'declined',
      },
      {
        title: t('opportunities.signed'),
        dataIndex: 'signed',
      },
      {
        title: t('opportunities.request_on_approve'),
        dataIndex: 'request_on_approve',
      },
      {
        title: t('opportunities.request_approved'),
        dataIndex: 'request_approved',
      },
      {
        title: t('opportunities.request_declined'),
        dataIndex: 'request_declined',
      },
      {
        title: t('opportunities.request_annulled'),
        dataIndex: 'request_annulled',
      },
    ],
    [t, data, params],
  );

  return (
    <Card className="overflow-visible">
      <Loader loading={isLoading} size="regular">
        <Card.Header>
          <ReportOpportunityFilter
            count={data?.branches?.length ? data?.branches?.length - 1 : 0}
            title="opportunitiesBranches"
            hiddenBranch
          />
        </Card.Header>

        <Table
          data={data?.branches}
          columns={columns}
          className="no-border contacts-table"
          emptyText={t('main.noData')}
        />
      </Loader>
    </Card>
  );
};
