import * as React from 'react';

import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader } from 'ebs-design';
import { Column, ReportsOpportunitiesTypes } from 'types';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useReportsAPI } from 'api';
import { useQueryParams } from 'hooks/useQueryParams';
import { ReportOpportunityFilter } from 'features/reports/Filters';

export const ReportOpportunities: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getReportOpportunities } = useReportsAPI();

  const { data: visits, isLoading } = useQuery<ReportsOpportunitiesTypes[]>(
    [
      'reports-opportunities',
      {
        ...params,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
      },
    ],
    getReportOpportunities,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const columns: Column<ReportsOpportunitiesTypes>[] = React.useMemo(
    () => [
      {
        title: t('lead.fullName'),
        render: ({ first_name, last_name }) => [first_name, last_name].join(' '),
      },

      {
        title: t('contacts.email'),
        dataIndex: 'email',
      },
      {
        title: t('opportunities.prospecting'),
        dataIndex: 'prospecting',
      },
      {
        title: t('opportunities.sent'),
        dataIndex: 'sent',
      },
      {
        title: t('opportunities.accepted'),
        dataIndex: 'accepted',
      },
      {
        title: t('opportunities.declined'),
        dataIndex: 'declined',
      },
      {
        title: t('opportunities.signed'),
        dataIndex: 'signed',
      },
      {
        title: t('opportunities.request_on_approve'),
        dataIndex: 'request_on_approve',
      },
      {
        title: t('opportunities.request_approved'),
        dataIndex: 'request_approved',
      },
      {
        title: t('opportunities.request_declined'),
        dataIndex: 'request_declined',
      },
      {
        title: t('opportunities.request_annulled'),
        dataIndex: 'request_annulled',
      },
    ],
    [t, visits, params],
  );

  return (
    <Card className="overflow-visible">
      <Loader loading={isLoading} size="regular">
        <Card.Header>
          <ReportOpportunityFilter count={visits?.length} title="opportunities" />
        </Card.Header>

        <Table
          data={visits}
          columns={columns}
          className="no-border contacts-table"
          emptyText={t('main.noData')}
        />
      </Loader>
    </Card>
  );
};
