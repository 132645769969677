import * as React from 'react';

import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader } from 'ebs-design';
import { Column, ReportOpportunitiesBranchesRefusalType } from 'types';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useReportsAPI } from 'api';
import { useQueryParams } from 'hooks/useQueryParams';
import { ReportOpportunityFilter } from 'features/reports/Filters';

interface FlattenedBranchData {
  id: string;
  name: string;
  refusal_reason_name: string;
  refusal_reason_count: number;
  total_row?: boolean;
}

export const ReportOpportunitiesBranchesRefusal: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getReportOpportunitiesBranchesRefusal } = useReportsAPI();

  const { data, isLoading } = useQuery<{
    branches: ReportOpportunitiesBranchesRefusalType[];
    totals: { name: string; count: number }[];
  }>(
    [
      'reports-opportunities-branches-refusal',
      {
        ...params,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
      },
    ],
    getReportOpportunitiesBranchesRefusal,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const tableFormattedData: FlattenedBranchData[] = React.useMemo(() => {
    if (!data) return [];

    const branchesData = data?.branches?.flatMap((branch) =>
      branch?.refusal_reasons.map((reason, index) => ({
        id: branch?.id,
        name: index === 0 ? branch?.name : '',
        refusal_reason_name: reason?.name,
        refusal_reason_count: reason?.count,
      })),
    );

    const totalRows = data.totals.map((total, index) => ({
      id: 'total',
      name: index === 0 ? t('reports.total') : '',
      refusal_reason_name: total?.name,
      refusal_reason_count: total?.count,
    }));

    return [...branchesData, ...totalRows];
  }, [data]);

  const columns: Column<FlattenedBranchData>[] = React.useMemo(
    () => [
      {
        title: t('documents.name'),
        dataIndex: 'name',
      },
      {
        title: t('opportunities.refusalReasons'),
        dataIndex: 'refusal_reason_name',
      },
      {
        title: t('opportunities.count'),
        dataIndex: 'refusal_reason_count',
      },
    ],
    [t],
  );

  return (
    <Card className="overflow-visible">
      <Loader loading={isLoading} size="regular">
        <Card.Header>
          <ReportOpportunityFilter
            count={data?.branches?.length}
            title="opportunitiesBranchesRefusal"
            hiddenBranch
          />
        </Card.Header>

        <Table
          data={tableFormattedData}
          columns={columns}
          className="no-border contacts-table"
          emptyText={t('main.noData')}
          rowKey={(record) => `${record.id}-${record.refusal_reason_name}`}
        />
      </Loader>
    </Card>
  );
};
