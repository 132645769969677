import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { ReportsLayout } from '../ReportsLayout';
import {
  ReportCalls,
  ReportCallsBranches,
  ReportDashboard,
  ReportExpenses,
  ReportOffline,
  ReportOnline,
  ReportSalesFunnel,
  ReportVisits,
  ReportOpportunities,
  ReportOpportunitiesBranches,
  ReportOpportunitiesBranchesRefusal,
  ReportOpportunitiesRefusal,
} from './Reports';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { ForbiddenError } from 'components/atoms';
import { Route as RouteProps } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';

export const Report: React.FC = () => {
  if (useRolePermission([UserRoleCRM.expert])) {
    return <ForbiddenError />;
  } else {
    const { t } = useTranslation();

    const routes = React.useMemo<RouteProps[]>(
      () => [
        {
          name: 'ReportExpenses',
          path: `/report/expenses`,
          exact: true,
          Component: ReportExpenses,
        },
        {
          name: 'ReportDashboard',
          path: `/report/dashboard`,
          exact: true,
          Component: ReportDashboard,
        },
        {
          name: 'ReportCalls',
          path: `/report/calls`,
          exact: true,
          Component: ReportCalls,
        },
        {
          name: 'ReportCallsBranches',
          path: `/report/calls-branches`,
          exact: true,
          Component: ReportCallsBranches,
        },
        {
          name: 'ReportOnline',
          path: `/report/online`,
          exact: true,
          Component: ReportOnline,
        },
        {
          name: 'ReportOffline',
          path: `/report/offline`,
          exact: true,
          Component: ReportOffline,
        },
        {
          name: 'ReportSalesFunnel',
          path: `/report/sales-funnel`,
          exact: true,
          Component: ReportSalesFunnel,
        },
        {
          name: 'Visits',
          path: `/report/visits`,
          exact: true,
          Component: ReportVisits,
        },
        {
          name: 'Opportunities',
          path: `/report/opportunities`,
          exact: true,
          Component: ReportOpportunities,
        },
        {
          name: 'OpportunitiesBranches',
          path: `/report/opportunities-branches`,
          exact: true,
          Component: ReportOpportunitiesBranches,
        },
        {
          name: 'OpportunitiesBranchesRefusal',
          path: `/report/opportunities-branches-refusal`,
          exact: true,
          Component: ReportOpportunitiesBranchesRefusal,
        },
        {
          name: 'OpportunitiesRefusal',
          path: `/report/opportunities-refusal`,
          exact: true,
          Component: ReportOpportunitiesRefusal,
        },
      ],
      [t],
    );

    return (
      <div className="contact-import-steps">
        <Switch>
          <ReportsLayout>
            {routes.map((item, key) => (
              <Route key={key} name={item.name} path={item.path} exact={item.exact}>
                <item.Component />
              </Route>
            ))}
          </ReportsLayout>
        </Switch>
      </div>
    );
  }
};
